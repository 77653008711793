import React, { useState } from 'react';
import { Box, Dialog, Grid, SpeedDial, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import legendYellowPopupImg from '../assets/legend_yellow_popup.png';
import legendGrayPopupImg from '../assets/legend_gray_popup.png';
import legendYellowClusterImg from '../assets/legend_yellow_cluster.png';
import legendGrayClusterImg from '../assets/legend_gray_cluster.png';
import legendEChargingPopup from '../assets/legend_e_charging_popup.png';

const Legend = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SpeedDial
        sx={{ position: 'absolute', bottom: 100, right: 5 }}
        ariaLabel="SpeedDial basic example"
        icon={<HelpIcon />}
        onClick={() => setOpen(true)}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          spacing={3}
          sx={{ padding: 3, backgroundColor: '#5a5a5a', color: 'white', maxHeight: '700px', overflowY: 'scroll' }}
        >
          <Grid container item padding={0} marginBottom={2} justifyContent="space-between">
            <Grid item>
              <Typography textAlign="center" fontWeight="bold" variant="h5">
                LEGENDE
              </Typography>
            </Grid>
            <Grid item>
              <CloseIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => setOpen(false)} />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item container spacing={3}>
              <Grid xs={12} sm={3} item container justifyContent="center">
                <Box sx={{ width: { xs: '50%', sm: '100%' } }}>
                  <img style={{ width: '100%' }} src={legendYellowPopupImg} alt="a yellow popup" />
                </Box>
              </Grid>
              <Grid sm={9} xs={12} item container flexDirection="column">
                <Typography fontWeight="bold" variant="body1">
                  Parkhaus / Parkplatz{' '}
                </Typography>
                <Typography variant="body1">
                  Dieser Marker definiert ein Parkhaus bzw. einen Parkplatz. Angezeigt wird einerseits die Anzahl an
                  freien Parkplätzen als auch der Name. Beim Anklicken des Markers öffnet sich ein Popup, welches alle
                  weiteren Informationen anzeigt.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid xs={12} sm={3} item container justifyContent="center">
                <Box sx={{ width: { xs: '50%', sm: '100%' } }}>
                  <img style={{ width: '100%' }} src={legendEChargingPopup} alt="a yellow popup" />
                </Box>
              </Grid>
              <Grid sm={9} xs={12} item container flexDirection="column">
                <Typography fontWeight="bold" variant="body1">
                  E-Ladestation
                </Typography>
                <Typography variant="body1">
                  Dieser Marker definiert eine E-Ladestation. Beim Anklicken öffnet sich ein Popup mit weiteren
                  Informationen. Dabei befindet sich in der Kopfzeile die Adresse der Ladestation und untenstehend die
                  Anzahl an verschiedenen Ladepunkten und Parkplätzen.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid xs={12} sm={3} item container justifyContent="center">
                <Box sx={{ width: { xs: '50%', sm: '100%' } }}>
                  <img style={{ width: '100%' }} src={legendGrayPopupImg} alt="a yellow popup" />
                </Box>
              </Grid>
              <Grid sm={9} xs={12} item container flexDirection="column">
                <Typography fontWeight="bold" variant="body1">
                  Info / Virtuelles Verkehrsschild{' '}
                </Typography>
                <Typography variant="body1">
                  Dieser Marker definiert ein virtuelles Verkehrsschild. Beim Anklicken des Markers öffnet sich ein
                  Popup, welches alle virtuellen Verkehrsschilder und weitere Details anzeigt. In einigen Fällen wird
                  dadurch auch ein Anzeigen einer VMS Route ausgelöst.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid xs={12} sm={3} item container justifyContent="center" alignItems="center">
                <img
                  style={{ width: '45px', height: '45px', marginRight: 3 }}
                  src={legendYellowClusterImg}
                  alt="a yellow popup"
                />
                <img
                  style={{ width: '45px', height: '45px', marginLeft: 3 }}
                  src={legendGrayClusterImg}
                  alt="a yellow popup"
                />
              </Grid>
              <Grid sm={9} xs={12} item container flexDirection="column">
                <Typography fontWeight="bold" variant="body1">
                  Cluster
                </Typography>
                <Typography variant="body1">
                  Wenn mehrere Marker nahe beieinander liegen, werden diese in einem Cluster zusammengefasst und die
                  Anzahl an darunterliegenden Markern angezeigt.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid xs={3} item container justifyContent="center" alignItems="center">
                <div style={{ height: '5px', width: '80%', visibility: 'hidden' }} />
                <div style={{ backgroundColor: '#F7614C', height: '5px', width: '80%' }} />
                <div style={{ backgroundColor: '#FFBA00', height: '5px', width: '80%' }} />
                <div style={{ backgroundColor: '#F2E32F', height: '5px', width: '80%' }} />
                <div style={{ backgroundColor: '#B0C830', height: '5px', width: '80%' }} />
                <div style={{ backgroundColor: '#B3B3B3', height: '5px', width: '80%' }} />
              </Grid>
              <Grid xs={9} item container flexDirection="column">
                <Typography fontWeight="bold" variant="body1">
                  Wahrscheinlichkeit
                </Typography>
                <Typography fontWeight="bold" color="#F7614C" variant="body1">
                  {`<= 25 %`}
                </Typography>
                <Typography fontWeight="bold" color="#FFBA00" variant="body1">
                  {`<= 50 %`}
                </Typography>
                <Typography fontWeight="bold" color="#F2E32F" variant="body1">
                  {`<= 75 %`}
                </Typography>
                <Typography fontWeight="bold" color="#B0C830" variant="body1">
                  {`<= 100 %`}
                </Typography>
                <Typography fontWeight="bold" color="#B3B3B3" variant="body1">
                  unbekannte Wahrscheinlichkeit (z.B. bei Parkverbot)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Legend;
