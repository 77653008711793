import React from 'react';
import LoginModal from '../components/LoginModal';

const LoginPage = () => (
  <>
    <LoginModal/>
  </>
);

export default LoginPage;

