import React from 'react';
import mapboxgl from 'mapbox-gl';
import { Avatar, SpeedDial, SpeedDialAction } from '@mui/material';
import AirplanemodeActiveSharpIcon from '@mui/icons-material/AirplanemodeActiveSharp';

type SpeedDialFlyerProps = {
  map: mapboxgl.Map;
};

const SpeedDialFlyer = ({ map }: SpeedDialFlyerProps) => {
  const handleFlyToStuttgart = () => {
    const coords = new mapboxgl.LngLat(9.1829, 48.7758);
    map.flyTo({
      center: coords,
      offset: [0, 100],
      zoom: 13,
    });
  };

  const handleFlyToMunich = () => {
    const coords = new mapboxgl.LngLat(11.582, 48.1351);
    map.flyTo({
      center: coords,
      offset: [0, 100],
      zoom: 13,
    });
  };

  const actions = [
    { icon: <Avatar sx={{ bgcolor: '#3a3a3a' }}>M</Avatar>, name: 'München', action: handleFlyToMunich },
    { icon: <Avatar sx={{ bgcolor: '#3a3a3a' }}>S</Avatar>, name: 'Stuttgart', action: handleFlyToStuttgart },
  ];

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: 'absolute', bottom: 33, right: 5 }}
      direction="left"
      icon={<AirplanemodeActiveSharpIcon />}
    >
      {actions.map((a) => (
        <SpeedDialAction key={a.name} aria-expanded="true" icon={a.icon} tooltipTitle={a.name} onClick={a.action} />
      ))}
    </SpeedDial>
  );
};

export default SpeedDialFlyer;
