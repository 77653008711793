import React from 'react';
import CarTypeModal from '../components/CarTypeModal';
import useStore from '../setup/global-state';
import Map from '../components/Map';

const StartPage = () => {
  const isMotorSet = useStore((state) => state.isMotorSet);

  return <>{!isMotorSet ? <CarTypeModal /> : <Map />}</>;
};

export default StartPage;
