import create from 'zustand';
import { devtools } from 'zustand/middleware';
import GlobalState from '../types/GlobalState';
import Motor from '../types/Motor';

const useStore = create<GlobalState>(
  devtools((set) => ({
    isAuth: false,
    setAuth: (auth: boolean) => set({ isAuth: auth }),
    motor: 'PMOTOR',
    isMotorSet: false,
    setMotorType: (m: Motor) => set({ motor: m, isMotorSet: true }),
  })),
);

export default useStore;
