import React, { ReactNode } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor:'rgba(0,0,30,0.4)'
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: 0,
  },
  containerLeft: {
    [theme.breakpoints.up('md')]: {
      width: '50%',

      '& $paper': {
        marginLeft: theme.spacing(5.75),
        marginRight: theme.spacing(5.75),
      },
    },
  },
  containerRight: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      width: '50%',

      '& $paper': {
        width: '100%',
        marginLeft: theme.spacing(5.75),
        marginRight: theme.spacing(5.75),
      },
    },
  },
}));

type CustomDialogProps = {
  position: 'center';
  children: ReactNode;
};

const CustomDialog = ({ position = 'center', children }: CustomDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
        <Dialog
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            data-click-away-ignore
            fullScreen={matches}
            maxWidth="xl"
            classes={{
              paper: classes.paper,
              container: { left: classes.containerLeft, right: classes.containerRight, center: undefined }[position],
            }}
            open>
          {children}
        </Dialog>
  );
};

export default CustomDialog;
