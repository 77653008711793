import React from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { GeoJsonProperties } from 'geojson';
import { ThemeProvider } from '@mui/material';
import infoMarkerSVG from '../assets/infoMarker.svg';
import infoIconSVG from '../assets/infoIcon.svg';
import arrowSVG from '../assets/arrow.svg';
import circleInfoSVG from '../assets/circleInfo.svg';
import theme from '../setup/theme';
import InfoMarkerPopup from '../components/InfoMarkerPopup';

type AddInfoMarkersProps = {
  map: mapboxgl.Map;
};

type CreateInfoMarkerPopupProps = {
  map: mapboxgl.Map;
  coordinates: [number, number];
  properties: GeoJsonProperties;
};

const setLayerVisibility = (map: mapboxgl.Map, visibility: 'visible' | 'none') => {
  map.setLayoutProperty('strategy-vms-route-layer-marker', 'visibility', visibility);
  map.setLayoutProperty('strategy-vms-route-layer-icon', 'visibility', visibility);
  map.setLayoutProperty('strategy-vms-route-layer-marker-clusters', 'visibility', visibility);
};

export const createInfoMarkerPopup = ({ properties, coordinates, map }: CreateInfoMarkerPopupProps) => {
  const popup = new mapboxgl.Popup({
    className: 'grey-popup',
    closeOnClick: false,
    closeButton: false,
    anchor: 'bottom',
  });

  const closeAction = () => {
    popup.remove();

    setLayerVisibility(map, 'visible');
    if (map.getLayer('route-line-layer')) map.removeLayer('route-line-layer');
    if (map.getLayer('route-line-layer-arrow')) map.removeLayer('route-line-layer-arrow');
    if (map.getSource('route-line-layer')) map.removeSource('route-line-layer');
  };

  map.on('closeAllPopups', () => {
    closeAction();
  });

  const popupContent = document.createElement('div');
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <InfoMarkerPopup properties={properties} closeAction={closeAction} />
    </ThemeProvider>,
    popupContent,
  );

  if (map) {
    popup.setLngLat(coordinates).setDOMContent(popupContent).setMaxWidth('100vw').addTo(map);
  }
};

/* eslint-disable no-param-reassign */
export const addInfoMarkers = ({ map }: AddInfoMarkersProps) => {
  const infoMarkerImg = new Image(100, 120);
  infoMarkerImg.onload = () => map?.addImage('infoMarkerSVG', infoMarkerImg);
  infoMarkerImg.src = infoMarkerSVG;

  const infoIconImg = new Image(60, 60);
  infoIconImg.onload = () => map?.addImage('infoIconSVG', infoIconImg);
  infoIconImg.src = infoIconSVG;

  const circleInfoImg = new Image(100, 100);
  circleInfoImg.onload = () => map?.addImage('circleInfoSVG', circleInfoImg);
  circleInfoImg.src = circleInfoSVG;

  const arrowImg = new Image(30, 30);
  arrowImg.onload = () => map?.addImage('arrowSVG', arrowImg);
  arrowImg.src = arrowSVG;

  map.addSource('strategy-vms-route-source', {
    type: 'geojson',
    data: process.env.REACT_APP_STUTTGART_STRATEGY_VMS_ROUTE_GEOJSON_URL,
    cluster: true,
    clusterMaxZoom: 18,
    clusterRadius: 80,
  });

  map.addLayer({
    id: 'strategy-vms-route-layer-marker',
    type: 'symbol',
    source: 'strategy-vms-route-source',
    layout: {
      'icon-image': 'infoMarkerSVG',
      'icon-anchor': 'bottom',
      'text-allow-overlap': true,
      'icon-allow-overlap': true,
    },
    filter: ['!has', 'point_count'],
  });

  map.addLayer({
    id: 'strategy-vms-route-layer-marker-icon',
    type: 'symbol',
    source: 'strategy-vms-route-source',
    layout: {
      'icon-image': 'infoIconSVG',
      'text-allow-overlap': true,
      'icon-allow-overlap': true,
      'icon-offset': [0, -70],
    },
    filter: ['!has', 'point_count'],
  });

  map.addLayer({
    id: 'strategy-vms-route-layer-marker-clusters',
    type: 'symbol',
    source: 'strategy-vms-route-source',
    layout: {
      'text-field': ['get', 'point_count'],
      'text-size': 25,
      'icon-image': 'circleInfoSVG',
      'icon-size': 0.6,
      'text-allow-overlap': false,
      'icon-allow-overlap': true,
      'text-justify': 'center',
    },
    paint: {
      'text-color': '#ffffff',
    },
    filter: ['has', 'point_count'],
  });

  // handle click-on-feature event -> open big popup and show route
  map.on('click', 'strategy-vms-route-layer-marker', (event) => {
    if (event.features && event.features[0].geometry.type === 'Point' && map) {
      // get coordinates of clicked feature
      const coords = event.features[0].geometry.coordinates.slice() as [number, number];

      map.flyTo({
        center: coords,
        offset: [0, 100],
        zoom: 18,
      });

      while (Math.abs(event.lngLat.lng - coords[0]) > 180) {
        coords[0] += event.lngLat.lng > coords[0] ? 360 : -360;
      }

      setLayerVisibility(map, 'none');

      createInfoMarkerPopup({
        coordinates: coords,
        map,
        properties: event.features[0].properties,
      });

      if (event.features[0]?.properties?.the_geom_parking_route) {
        map.addLayer(
          {
            id: 'route-line-layer',
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    properties: {},
                    geometry: JSON.parse(event.features[0]?.properties?.the_geom_parking_route),
                  },
                ],
              },
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-width': 6,
            },
          },
          'parking-facilities-layer-name',
        );

        map.addLayer(
          {
            id: 'route-line-layer-arrow',
            type: 'symbol',
            source: 'route-line-layer',
            layout: {
              'symbol-placement': 'line',
              'icon-image': 'arrowSVG',
            },
          },
          'parking-facilities-layer-name',
        );
      }
    }
  });

  map.on('mouseenter', 'strategy-vms-route-source', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'strategy-vms-route-source', () => {
    map.getCanvas().style.cursor = '';
  });

  // re-fetch data every 5 minutes
  setInterval(() => {
    (map.getSource('strategy-vms-route-source') as GeoJSONSource).setData(
      process.env.REACT_APP_STUTTGART_STRATEGY_VMS_ROUTE_GEOJSON_URL as string,
    );
  }, 300000);
};
