import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Variant } from '@mui/material/styles/createTypography';

type LogoProps = {
  variant: Variant;
};

const Logo = ({ variant }: LogoProps) => (
  <Button component={Link} to="/" sx={{ padding: '13px 0' }}>
    <Typography
      fontFamily="Montserrat"
      color="#3a3a3a"
      fontWeight={600}
      sx={{ textTransform: 'none' }}
      variant={variant}
      align="left"
    >
      BASt
    </Typography>
    <Typography
      fontFamily="Montserrat"
      color="#3a3a3a"
      fontWeight={300}
      variant={variant}
      align="left"
      sx={{ textTransform: 'none' }}
    >
      PARKEN
    </Typography>
  </Button>
);

export default Logo;
