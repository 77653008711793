import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GeoJsonProperties } from 'geojson';

type ElsMarkerPopupProps = {
  closeAction: () => void;
  properties: GeoJsonProperties;
};

const ElsMarkerPopup = ({ closeAction, properties }: ElsMarkerPopupProps) => (
  <Box
    sx={{
      backgroundColor: 'primary.main',
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 92%, 56% 92%, 50% 100%, 44% 92%, 0% 92%)',
      fontFamily: 'Source Sans Pro',
      padding: '10px 20px 30px',
      height: {
        xs: '250px',
      },
      width: {
        xs: '90vw',
        sm: '40vw',
        md: '28vw',
        lg: '18vw',
      },
    }}
  >
    <Grid container flexDirection="column">
      <Grid item container>
        <Grid item xs={8}>
          <Typography variant="h5">{properties?.address}</Typography>
        </Grid>
        <Grid item container xs={4} justifyContent="flex-end">
          <CloseIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => closeAction()} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={1}
        flexDirection="column"
        sx={{
          marginTop: '8px',
          height: '200px',
        }}
      >
        <Grid item container>
          <Grid item xs={12}>
            <Typography variant="body1">E-Ladestationen: {properties?.num_els}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Parkplätze: {properties?.num_parking_spaces}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Ladepunkte: {properties?.num_total_charging_points}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Normal-Ladepunkte: {properties?.num_ac_charging_points}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Schnell-Ladepunkte: {properties?.num_dc_charging_points}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default ElsMarkerPopup;
