import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deDE } from '@mui/material/locale';

const theme = responsiveFontSizes(
  createTheme(
    {
      palette: {
        primary: {
          main: '#ffba00',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#3a3a3a',
          contrastText: '#ffffff',
        },
      },
      typography: {
        fontFamily: ['Source Sans Pro', 'Montserrat'].join(','),
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            '.mapboxgl-popup-content': {
              background: 'none',
              padding: 0,
              boxShadow: 'none',
              borderRadius: 0,
            },
            '.mapboxgl-popup-tip': {
              display: 'none',
            },
          },
        },
        MuiUseMediaQuery: {
          defaultProps: {
            noSsr: true,
          },
        },
      },
    },
    deDE,
  ),
);

export default theme;
