import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import './styles/MenuAppBar.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import SubjectSharpIcon from '@mui/icons-material/SubjectSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import DirectionsCarSharpIcon from '@mui/icons-material/DirectionsCarSharp';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import Drawer from '@mui/material/Drawer';
import { Menu } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import useStore from '../setup/global-state';
import Logo from './Logo';

const MenuAppBar = () => {
  const [open, setOpen] = useState(false);
  const setAuth = useStore((state) => state.setAuth);
  const location = useLocation();

  const handleLogout = () => {
    setAuth(false);
    localStorage.removeItem('authToken');
  };

  const handleMotorChange = () => {
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ color: 'black', background: '#f5f5f5' }}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Logo variant="h4" />
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setOpen((prevState) => !prevState)}
              color="inherit"
            >
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: { xs: '50vw', sm: '50vw', md: '40vw', lg: '25vw' },
          }}
          role="presentation"
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}
        >
          <List>
            <ListItem button component={Link} to="/">
              <ListItemIcon>
                <HomeSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Startseite" />
            </ListItem>
            {location.pathname === '/' ? (
              <ListItem button onClick={handleMotorChange}>
                <ListItemIcon>
                  <DirectionsCarSharpIcon />
                </ListItemIcon>
                <ListItemText primary="Motortyp ändern" />
              </ListItem>
            ) : (
              <></>
            )}

            <Divider />
            <ListItem button component={Link} to="/ueber-das-projekt">
              <ListItemIcon>
                <InfoSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Über das Projekt" />
            </ListItem>
            <ListItem button component="a" href="https://www.trafficon.eu/impressum/">
              <ListItemIcon>
                <SubjectSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Impressum" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/login" onClick={handleLogout}>
              <ListItemIcon>
                <LogoutSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default MenuAppBar;
