import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { GeoJsonProperties } from 'geojson';
import CloseIcon from '@mui/icons-material/Close';
import ReactHtmlParser from 'react-html-parser';
import useStore from '../setup/global-state';

type InfoMarkerPopupProps = {
  closeAction: () => void;
  properties: GeoJsonProperties;
};

// eslint-disable-next-line
const InfoMarkerPopup = ({ closeAction, properties }: InfoMarkerPopupProps) => {
  const motorType = useStore((state) => state.motor);

  const fullInfoHtml = ReactHtmlParser(properties?.vms_content);
  const filteredInfoHtml =
    motorType === 'EHMOTOR' ? fullInfoHtml[0].props.children[1] : fullInfoHtml[0].props.children[3];

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 92%, 56% 92%, 50% 100%, 44% 92%, 0% 92%)',
        fontFamily: 'Source Sans Pro',
        padding: '10px 10px 60px',
        height: '50vh',
        width: {
          xs: '90vw',
          sm: '50vw',
          md: '30vw',
          lg: '20vw',
        },
      }}
    >
      <Grid container flexDirection="column" alignItems="center">
        <Grid item container>
          <Grid item container xs={12} justifyContent="flex-end">
            <CloseIcon sx={{ '&:hover': { cursor: 'pointer' }, color: 'white' }} onClick={() => closeAction()} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={1}
          sx={{
            height: '35vh',
            overflowY: 'auto',
            padding: '0 50px',
            marginTop: '10px',
          }}
          alignItems="center"
          justifyContent="center"
        >
          {filteredInfoHtml}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoMarkerPopup;
