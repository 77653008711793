import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import { Box, ThemeProvider } from '@mui/material';
import mainTheme from './setup/theme';
import StartPage from './pages/StartPage';
import ImprintPage from './pages/ImprintPage';
import AboutPage from './pages/AboutPage';
import MenuAppBar from './components/MenuAppBar';
import LoginPage from './pages/LoginPage';
import useStore from './setup/global-state';

const AppContent = () => {
  const isAuth = useStore((state) => state.isAuth);

  return useRoutes([
    { path: '/', element: isAuth ? <StartPage /> : <LoginPage /> }, // Change to <LoginPage/> after auth server is deployed
    { path: '/login', element: <LoginPage /> },
    { path: '/ueber-das-projekt', element: <AboutPage /> },
    { path: '/impressum', element: <ImprintPage /> },
  ]);
};

const App = () => {
  const setAuth = useStore((state) => state.setAuth);
  setAuth(false);

  async function verifyUser() {
    const jsonResponse = await fetch(process.env.REACT_APP_AUTH_SERVICE_VERIFY as string, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: localStorage.getItem('authToken') }),
    }).then((data) => data.json());

    if (jsonResponse.token != null && jsonResponse.error === '') setAuth(true);
    else setAuth(false);
  }

  verifyUser();

  return (
    <Router>
      <ThemeProvider theme={mainTheme}>
        <Box width="100vw" height="100vh" display="flex" flexDirection="column">
          <Box>
            <MenuAppBar />
          </Box>
          <Box flexGrow={1}>
            <AppContent />
          </Box>
        </Box>
      </ThemeProvider>
    </Router>
  );
};

export default App;
