import React from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { GeoJsonProperties } from 'geojson';
import { ThemeProvider } from '@mui/material';
import markerSVG from '../assets/marker.svg';
import circleSVG from '../assets/circle.svg';
import theme from '../setup/theme';
import ParkingMarkerPopUp from '../components/ParkingMarkerPopUp';
import Motor from '../types/Motor';

type AddParkingMarkersProps = {
  map: mapboxgl.Map;
  motorType: Motor;
};

type CreateParkingMarkerPopupProps = {
  map: mapboxgl.Map;
  coordinates: [number, number];
  properties: GeoJsonProperties;
};

export const createParkingMarkerPopup = ({ properties, coordinates, map }: CreateParkingMarkerPopupProps) => {
  const popup = new mapboxgl.Popup({
    className: 'orange-popup',
    closeOnClick: false,
    closeButton: false,
    anchor: 'bottom',
  });

  const closeAction = () => {
    popup.remove();
    map.setLayoutProperty('parking-facilities-layer-name', 'visibility', 'visible');
    map.setLayoutProperty('parking-facilities-layer-free', 'visibility', 'visible');
    map.setLayoutProperty('parking-facilities-layer-clusters', 'visibility', 'visible');
  };

  map.on('closeAllPopups', () => {
    closeAction();
  });

  const popupContent = document.createElement('div');
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <ParkingMarkerPopUp properties={properties} closeAction={closeAction} />
    </ThemeProvider>,
    popupContent,
  );

  if (map) {
    popup.setLngLat(coordinates).setDOMContent(popupContent).setMaxWidth('100vw').addTo(map);
  }
};

/* eslint-disable no-param-reassign */
export const addParkingMarkers = ({ map, motorType }: AddParkingMarkersProps) => {
  const markerImg = new Image(100, 120);
  markerImg.onload = () => map?.addImage('markerSVG', markerImg);
  markerImg.src = markerSVG;

  const circleImg = new Image(100, 100);
  circleImg.onload = () => map?.addImage('circleSVG', circleImg);
  circleImg.src = circleSVG;

  map.addSource('parking-facilities-source', {
    type: 'geojson',
    data: process.env.REACT_APP_STUTTGART_PARKING_FACILITIES_GEOJSON_URL,
    cluster: true,
    clusterMaxZoom: 18,
    clusterRadius: 80,
  });

  map.addLayer({
    id: 'parking-facilities-layer-name',
    type: 'symbol',
    source: 'parking-facilities-source',
    layout: {
      'text-field': ['get', 'name'],
      'text-size': 13,
      'icon-image': 'markerSVG',
      'icon-anchor': 'bottom',
      'icon-text-fit': 'width',
      'icon-text-fit-padding': [15, 13, 15, 13],
      'text-allow-overlap': true,
      'icon-allow-overlap': true,
      'text-justify': 'center',
      'text-max-width': 9,
      'text-offset': [0, -5],
      'text-padding': 2,
    },
    filter: ['!has', 'point_count'],
  });

  if (motorType === 'EHMOTOR') {
    map.addLayer({
      id: 'parking-facilities-layer-free',
      type: 'symbol',
      source: 'parking-facilities-source',
      layout: {
        'text-field': [
          'to-string',
          ['+', ['number', ['get', 'short_term_parking_free']], ['number', ['get', 'e_vehicle_charging_free']]],
        ],
        'text-size': 18,
        'text-allow-overlap': true,
        'icon-allow-overlap': true,
        'text-offset': [0, -6],
      },
      filter: ['!has', 'point_count'],
    });
  } else {
    map.addLayer({
      id: 'parking-facilities-layer-free',
      type: 'symbol',
      source: 'parking-facilities-source',
      layout: {
        'text-field': ['get', 'short_term_parking_free'],
        'text-size': 18,
        'text-allow-overlap': true,
        'icon-allow-overlap': true,
        'text-offset': [0, -6],
      },
      filter: ['!has', 'point_count'],
    });
  }

  map.addLayer({
    id: 'parking-facilities-layer-clusters',
    type: 'symbol',
    source: 'parking-facilities-source',
    layout: {
      'text-field': ['get', 'point_count'],
      'text-size': 25,
      'icon-image': 'circleSVG',
      'icon-size': 0.6,
      'text-allow-overlap': false,
      'icon-allow-overlap': true,
      'text-justify': 'center',
    },
    filter: ['has', 'point_count'],
  });

  // handle click-on-feature event -> open big popup
  map.on('click', 'parking-facilities-layer-name', (event) => {
    if (event.features && event.features[0].geometry.type === 'Point' && map) {
      // get coordinates of clicked feature
      const coords = event.features[0].geometry.coordinates.slice() as [number, number];

      map.flyTo({
        center: coords,
        offset: [0, 100],
        zoom: 18,
      });

      while (Math.abs(event.lngLat.lng - coords[0]) > 180) {
        coords[0] += event.lngLat.lng > coords[0] ? 360 : -360;
      }

      map.setLayoutProperty('parking-facilities-layer-name', 'visibility', 'none');
      map.setLayoutProperty('parking-facilities-layer-free', 'visibility', 'none');
      map.setLayoutProperty('parking-facilities-layer-clusters', 'visibility', 'none');

      createParkingMarkerPopup({
        coordinates: coords,
        map,
        properties: event.features[0].properties,
      });
    }
  });

  map.on('mouseenter', 'parking-facilities-layer-name', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'parking-facilities-layer-name', () => {
    map.getCanvas().style.cursor = '';
  });

  // re-fetch data every 5 minutes
  setInterval(() => {
    (map.getSource('parking-facilities-source') as GeoJSONSource).setData(
      process.env.REACT_APP_STUTTGART_PARKING_FACILITIES_GEOJSON_URL as string,
    );
  }, 300000);
};
