import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { addParkingMarkers } from '../creators/parkingMarkerPopupCreator';
import { addInfoMarkers } from '../creators/infoMarkerPopupCreator';
import SpeedDialFlyer from './SpeedDialFlyer';
import { addStreetLines } from '../creators/streeLineCreator';
import { addElsMarkers } from '../creators/elsMarkerPopupCreator';
import useStore from '../setup/global-state';
import Legend from './Legend';

const Map = () => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map>();

  const motorType = useStore((state) => state.motor);

  const [dialVisible, setDialVisible] = useState(false);

  useEffect(() => {
    // Initialize map only once
    if (!mapContainer.current || map.current) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [9.1829321, 48.7758459],
      zoom: 12,
      touchPitch: false,
      pitchWithRotate: false,
      dragRotate: false,
    });
    map.current.touchZoomRotate.disableRotation();

    map.current.on('load', () => {
      if (map.current) {
        map.current.on('click', () => {
          if (map.current) map.current.fire('closeAllPopups');
        });

        // Stuttgart
        addInfoMarkers({ map: map.current });
        addParkingMarkers({ map: map.current, motorType });

        // Munich
        addElsMarkers({ map: map.current });
        addStreetLines({ map: map.current });

        setDialVisible(true);
      }
    });
  });

  return (
    <>
      <div ref={mapContainer} style={{ height: '100%', width: '100%' }} />
      {map.current && dialVisible ? <SpeedDialFlyer map={map.current} /> : <></>}
      <Legend />
    </>
  );
};

export default Map;
