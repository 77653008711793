import * as React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import BoltIcon from '@mui/icons-material/Bolt';
import VideocamIcon from '@mui/icons-material/Videocam';
import EuroIcon from '@mui/icons-material/Euro';
import TodayIcon from '@mui/icons-material/Today';
import EvStationIcon from '@mui/icons-material/EvStation';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ElevatorIcon from '@mui/icons-material/Elevator';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import UmbrellaIcon from '@mui/icons-material/Umbrella';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SchoolIcon from '@mui/icons-material/School';
import WcIcon from '@mui/icons-material/Wc';
import FemaleIcon from '@mui/icons-material/Female';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import AccessibleIcon from '@mui/icons-material/Accessible';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { FeatureCollection, GeoJsonProperties, Feature } from 'geojson';
import useStore from '../setup/global-state';

type ParkingMarkerPopupProps = {
  closeAction: () => void;
  properties: GeoJsonProperties;
};

const generateTimeString = (feature: Feature): string => {
  const startTime = feature?.properties?.start_time;
  const endTime = feature?.properties?.end_time;
  if (startTime !== endTime) {
    return `${startTime} - ${endTime}`;
  }
  return '';
};

const generateDayString = (feature: Feature): string => {
  const arr = [];

  if (feature?.properties?.valid_monday) arr.push('Mo.');
  if (feature?.properties?.valid_tuesday) arr.push('Di.');
  if (feature?.properties?.valid_wednesday) arr.push('Mi.');
  if (feature?.properties?.valid_thursday) arr.push('Do.');
  if (feature?.properties?.valid_friday) arr.push('Fr.');
  if (feature?.properties?.valid_saturday) arr.push('Sa.');
  if (feature?.properties?.valid_sunday) arr.push('So.');

  if (arr.length !== 0) return `${arr.join(', ')}`;
  return '';
};

const ParkingMarkerPopUp = ({ closeAction, properties }: ParkingMarkerPopupProps) => {
  const [parkingRateFeatures, setParkingRateFeatures] = useState<FeatureCollection>();
  const [chargingRateFeatures, setChargingRateFeatures] = useState<FeatureCollection>();
  const motorType = useStore((state) => state.motor);

  useEffect(() => {
    // fetch parking rates
    fetch(
      `${process.env.REACT_APP_STUTTGART_PARKING_RATE_GEOJSON_URL}&cql_filter=parking_facility_id=${properties?.parking_facility_id}`,
    )
      .then((data) => data.json())
      .then((geojson) => setParkingRateFeatures(geojson));

    // fetch charging rates
    fetch(
      `${process.env.REACT_APP_STUTTGART_CHARGING_RATE_GEOJSON_URL}&cql_filter=parking_facility_id=${properties?.parking_facility_id}`,
    )
      .then((data) => data.json())
      .then((geojson) => setChargingRateFeatures(geojson));
    // eslint-disable-next-line
  }, [properties?.parking_facility_id]);

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 92%, 56% 92%, 50% 100%, 44% 92%, 0% 92%)',
        fontFamily: 'Source Sans Pro',
        padding: '10px 10px 60px',
        height: '40vh',
        width: {
          xs: '90vw',
          sm: '50vw',
          md: '30vw',
          lg: '25vw',
        },
      }}
    >
      <Grid container flexDirection="column">
        <Grid item container>
          <Grid item xs={2}>
            <Typography variant="h5">
              {motorType === 'EHMOTOR'
                ? `${properties?.short_term_parking_free + properties?.e_vehicle_charging_free}`
                : `${properties?.short_term_parking_free}`}
            </Typography>
          </Grid>
          <Grid container item xs={8} alignItems="center" justifyContent="center">
            <Typography align="center" lineHeight={1} variant="body1">
              {properties?.name}
            </Typography>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end">
            <CloseIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => closeAction()} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={1}
          sx={{
            height: '25vh',
            overflowY: 'auto',
            padding: '0 50px',
            marginTop: '10px',
          }}
        >
          <Grid item sx={{ width: '100%' }}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              ADRESSE
            </Divider>
          </Grid>
          <Grid item container alignItems="flex-start" flexDirection="column">
            <Grid item>
              <Typography variant="body2">{properties?.street}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {properties?.postal_code} {properties?.city} {properties?.country}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              EIGENSCHAFTEN
            </Divider>
          </Grid>
          <Grid item container spacing={1} justifyContent="flex-start">
            {properties?.long_term_parking && (
              <Grid item>
                <Chip icon={<LocalParkingIcon />} label="Langzeit" />
              </Grid>
            )}
            {properties?.short_term_parking && (
              <Grid item>
                <Chip icon={<LocalParkingIcon />} label="Kurzzeit" />
              </Grid>
            )}
            {properties?.parking_space_reservable && (
              <Grid item>
                <Chip icon={<TodayIcon />} label="Reservierbar" />
              </Grid>
            )}
            {properties?.e_parking && (
              <Grid item>
                <Chip icon={<BoltIcon />} label="E-Parken" />
              </Grid>
            )}
            {properties?.elevator && (
              <Grid item>
                <Chip icon={<ElevatorIcon />} label="Lift" />
              </Grid>
            )}
            {properties?.security_video && (
              <Grid item>
                <Chip icon={<VideocamIcon />} label="Video" />
              </Grid>
            )}
            {properties?.toilet && (
              <Grid item>
                <Chip icon={<WcIcon />} label="WC" />
              </Grid>
            )}
            {properties?.parking_space_disabled && (
              <Grid item>
                <Chip icon={<AccessibleIcon />} label="Behindertenstellplätze" />
              </Grid>
            )}
            {properties?.e_vehicle_charging_disabled && (
              <Grid item>
                <Chip icon={<EvStationIcon />} label="Punktuelles Laden" />
              </Grid>
            )}

            {properties?.parking_space_family && (
              <Grid item>
                <Chip icon={<FamilyRestroomIcon />} label="Fam. Parken" />
              </Grid>
            )}
            {properties?.parking_space_women && (
              <Grid item>
                <Chip icon={<FemaleIcon />} label="Frauenparkplatz" />
              </Grid>
            )}
            {properties?.park_and_ride && (
              <Grid item>
                <Chip icon={<LocalParkingIcon />} label="P+R" />
              </Grid>
            )}
            {properties?.umbrella_machine && (
              <Grid item>
                <Chip icon={<UmbrellaIcon />} label="Regenschirm" />
              </Grid>
            )}
            {properties?.student_art && (
              <Grid item>
                <Chip icon={<SchoolIcon />} label="Student Art" />
              </Grid>
            )}
            {properties?.emergency_calls && (
              <Grid item>
                <Chip icon={<LocalPhoneIcon />} label="Notruftelefon" />
              </Grid>
            )}
            {properties?.service_staff && (
              <Grid item>
                <Chip icon={<EmojiPeopleIcon />} label="Personal vor Ort" />
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              PLÄTZE
            </Divider>
          </Grid>
          <Grid item container>
            {properties?.short_term_parking && (
              <Grid item xs={12}>
                <Typography variant="body2">Kurzzeit Frei: {properties?.short_term_parking_free}</Typography>
              </Grid>
            )}
            {properties?.e_parking && (
              <Grid item xs={12}>
                <Typography variant="body2">E-Auto Frei: {properties?.e_vehicle_charging_free}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body2">Total: {properties?.num_parking_space_total}</Typography>
            </Grid>
            {properties?.parking_space_disabled && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  Behindertenstellplätze: {properties?.num_parking_space_disabled}
                </Typography>
              </Grid>
            )}
            {properties?.parking_space_women && (
              <Grid item xs={12}>
                <Typography variant="body2">Frauen: {properties?.num_parking_space_women}</Typography>
              </Grid>
            )}
            {properties?.parking_space_family && (
              <Grid item xs={12}>
                <Typography variant="body2">Familien: {properties?.num_parking_space_family}</Typography>
              </Grid>
            )}
            {properties?.e_parking && (
              <Grid item xs={12}>
                <Typography variant="body2">E-Auto: {properties?.num_parking_space_e_vehicle}</Typography>
              </Grid>
            )}
          </Grid>
          {(properties?.e_vehicle_charging_e_parken_karte || properties?.e_vehicle_charging_enbwmobility) && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <Divider textAlign="left" sx={{ width: '100%' }}>
                  LADEKARTEN
                </Divider>
              </Grid>
              <Grid item container spacing={1} justifyContent="flex-start">
                {properties?.e_vehicle_charging_e_parken_karte && (
                  <Grid item>
                    <Chip icon={<CardMembershipIcon />} label="eParken-Karte" />
                  </Grid>
                )}
                {properties?.e_vehicle_charging_enbwmobility && (
                  <Grid item>
                    <Chip icon={<CardMembershipIcon />} label="EnBW mobility+" />
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <Grid item sx={{ width: '100%' }}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              BEZAHLUNG
            </Divider>
          </Grid>
          <Grid item container spacing={1} justifyContent="flex-start">
            {properties?.payment_credit_card && (
              <Grid item>
                <Chip icon={<CreditCardIcon />} label="Kreditkarte" />
              </Grid>
            )}
            {properties?.payment_cash && (
              <Grid item>
                <Chip icon={<EuroIcon />} label="Bar" />
              </Grid>
            )}
            {properties?.payment_ec && (
              <Grid item>
                <Chip icon={<CreditCardIcon />} label="EC" />
              </Grid>
            )}
            {properties?.payment_visa && (
              <Grid item>
                <Chip icon={<CreditCardIcon />} label="VISA" />
              </Grid>
            )}
            {properties?.payment_parken_laden_karte && (
              <Grid item>
                <Chip icon={<CardMembershipIcon />} label="ParkenLaden" />
              </Grid>
            )}
            {properties?.payment_parknow_karte && (
              <Grid item>
                <Chip icon={<CardMembershipIcon />} label="ParkNow" />
              </Grid>
            )}
          </Grid>
          {properties?.mobility_card_parken_laden_karte ||
          properties?.mobility_card_parknow_karte ||
          properties?.mobility_card_polygocard ||
          properties?.mobility_card_v_parken_karte ? (
            <>
              <Grid item sx={{ width: '100%' }}>
                <Divider textAlign="left" sx={{ width: '100%' }}>
                  MOBILITÄTSKARTEN
                </Divider>
              </Grid>
              <Grid item container spacing={1} justifyContent="flex-start">
                {properties?.mobility_card_parken_laden_karte && (
                  <Grid item>
                    <Chip icon={<CardMembershipIcon />} label="ParkenLaden" />
                  </Grid>
                )}
                {properties?.mobility_card_parknow_karte && (
                  <Grid item>
                    <Chip icon={<CardMembershipIcon />} label="ParkNow" />
                  </Grid>
                )}
                {properties?.mobility_card_polygocard && (
                  <Grid item>
                    <Chip icon={<CardMembershipIcon />} label="polygoCard" />
                  </Grid>
                )}
                {properties?.mobility_card_v_parken_karte && (
                  <Grid item>
                    <Chip icon={<CardMembershipIcon />} label="vParken" />
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <></>
          )}

          {parkingRateFeatures?.features.length !== 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <Divider textAlign="left" sx={{ width: '100%' }}>
                  PARKTARIFE
                </Divider>
              </Grid>
              <Grid item container alignItems="flex-start" flexDirection="column" spacing={1}>
                {parkingRateFeatures?.features.map((feature: Feature) => (
                  <Grid key={feature?.id} item container flexDirection="row">
                    <Grid item container xs={6} justifySelf="flex-start" flexDirection="column">
                      <Typography fontWeight="bold" variant="body2">
                        {feature?.properties?.description}
                      </Typography>
                      <Typography variant="body2">{generateTimeString(feature)}</Typography>
                      <Typography variant="body2">{generateDayString(feature)}</Typography>
                      <Typography variant="body2">Taktung: {feature?.properties?.clocking}</Typography>
                    </Grid>
                    <Grid item xs={6} justifySelf="flex-end">
                      <Typography variant="body2" textAlign="right">
                        <Typography fontWeight="bold" variant="body2" textAlign="right">
                          {feature?.properties?.price} €
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {chargingRateFeatures?.features.length !== 0 && (
            <>
              <Grid item sx={{ width: '100%' }}>
                <Divider textAlign="left" sx={{ width: '100%' }}>
                  LADETARIFE
                </Divider>
              </Grid>
              <Grid item container alignItems="flex-start" flexDirection="column" spacing={1}>
                {chargingRateFeatures?.features.map((feature: Feature) => (
                  <Grid key={feature?.id} item container flexDirection="row">
                    <Grid item container xs={8} justifySelf="flex-start" flexDirection="column">
                      <Typography fontWeight="bold" variant="body2">
                        {feature?.properties?.description}
                      </Typography>
                      <Typography variant="body2">{generateTimeString(feature)}</Typography>
                      <Typography variant="body2">{generateDayString(feature)}</Typography>
                      <Typography variant="body2">Taktung: {feature?.properties?.clocking}</Typography>
                    </Grid>
                    <Grid item xs={4} justifySelf="flex-end">
                      <Typography variant="body2" textAlign="right" fontWeight="bold">
                        {feature?.properties?.price} €
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <Grid item sx={{ width: '100%' }}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              ABMESSUNGEN
            </Divider>
          </Grid>
          <Grid item container>
            {properties?.entrance_height && (
              <Grid item xs={12}>
                <Typography variant="body2">Höhe Einfahrt: {properties?.entrance_height}m</Typography>
              </Grid>
            )}
            {properties?.entrance_width && (
              <Grid item xs={12}>
                <Typography variant="body2">Breite Einfahrt: {properties?.entrance_width}m</Typography>
              </Grid>
            )}
            {properties?.parking_space_width_max && (
              <Grid item xs={12}>
                <Typography variant="body2">Max-Breite Parkplatz: {properties?.parking_space_width_max}m</Typography>
              </Grid>
            )}
            {properties?.parking_space_width_min && (
              <Grid item xs={12}>
                <Typography variant="body2">Min-Breite Parkplatz: {properties?.parking_space_width_min}m</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              KONTAKT
            </Divider>
          </Grid>
          <Grid item container alignItems="flex-start" flexDirection="column">
            <Grid item>
              <Typography variant="body2">
                {properties?.contact_person_first_name} {properties?.contact_person_name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{properties?.contact_mail}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{properties?.contact_phone_number}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ParkingMarkerPopUp;
