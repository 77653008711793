import React, { ReactChild } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    overflow: 'auto',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {},
}));

// eslint-disable-next-line react/require-default-props
type ContentPageProps = { children: ReactChild; containerClassName?: string };

const ContentPageWrapper = ({ children, containerClassName }: ContentPageProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Container component="main" className={clsx(classes.container, containerClassName)}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default ContentPageWrapper;
