import React, { useState } from 'react';
import { Box, Button, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Alert, Input, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomDialog from './CustomDialog';
import useStore from '../setup/global-state';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
    },
  },
  contentLarge: {
    [theme.breakpoints.up('xl')]: {
      paddingInline: `${theme.spacing(10)}px ${theme.spacing(10)}px ${theme.spacing(5)}px`,
    },
  },
  actions: {
    display: 'block',
    paddingRight: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      left: 0,
      top: theme.spacing(-2),
      height: theme.spacing(2),
    },

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(8),
    },

    '& .MuiButton-root': {
      display: 'block',
      margin: '0 auto',
    },
  },
  actionsLarge: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  buttonLogin: {
    color: 'black',
    backgroundColor: '#FFBA00',
    boxShadow: 'none',
    margin: 0,
    marginBottom: theme.spacing(2),
    textTransform: 'none',
    fontSize: 18,
    borderRadius: 0,
    textAlign: 'center',
  },
  passwordInput: {
    paddingBottom: 40,
  },
  signIntSubtext: {
    display: 'inline-flex',
    textAlign: 'center',
    alignContent: 'center',
  },
  input: {
    textAlign: 'left',
    width: '100%',
    height: 50,
  },
  inputForm: {
    verticalAlign: 'top',
    justifyContent: 'center',
  },
}));

async function loginUser(username: string, password: string) {
  return fetch(process.env.REACT_APP_AUTH_SERVICE_LOGIN as string, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  }).then((data) => data.text());
}

const LoginModal = () => {
  const classes = useStyles();
  const setAuth = useStore((state) => state.setAuth);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async () => {
    const response = await loginUser(username, password);
    const jsonResponse = JSON.parse(response);
    if (jsonResponse.token != null && jsonResponse.error === '') setAuth(true);
    else {
      setErrorText(jsonResponse.error);
      setErrorOpen(true);
    }
    localStorage.setItem('authToken', jsonResponse.token);
  };

  const handleErrorClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
    setErrorText('');
  };

  return (
    <CustomDialog position="center">
      <DialogContent className={clsx(classes.content, classes.contentLarge)}>
        <Box>
          <div>
            <Typography variant="h4" align="center">
              Sign in to
            </Typography>
            <div className={classes.signIntSubtext}>
              <h1 id="app-title-bold">BASt</h1>
              <h1 id="app-title-light">PARKEN</h1>
            </div>
          </div>
        </Box>
      </DialogContent>

      <DialogActions className={clsx(classes.actions, classes.contentLarge)}>
        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            {errorText}
          </Alert>
        </Snackbar>
        <form>
          <div className={classes.inputForm}>
            <div>
              <Input
                type="text"
                className={classes.input}
                placeholder="Username"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className={classes.passwordInput}>
              <Input
                type="password"
                className={classes.input}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <Button className={classes.buttonLogin} component={Link} to="/" onClick={handleSubmit}>
            Log in
          </Button>
        </form>
      </DialogActions>
    </CustomDialog>
  );
};

export default LoginModal;
