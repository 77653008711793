import React from 'react';
import { Grid, Typography } from '@mui/material';
import ContentPageWrapper from '../components/ContentPageWrapper';
import Logo from '../components/Logo';

const AboutPage = () => (
  <ContentPageWrapper>
    <Grid container direction="column" alignItems="center">
      <Logo variant="h3" />
      <Grid item sx={{ width: { xs: '85vw', sm: '60vw' } }}>
        <Typography gutterBottom variant="h2" align="center" style={{ paddingBottom: 20 }}>
          Über das Projekt
        </Typography>
        <Typography gutterBottom>
          Die Demonstrator Anwendung ist im Rahmen des Forschungsprojekts „Integration von öffentlichem und privatem
          Parkraummanagement“ im Auftrag der Bundesanstalt für Straßenwesen (BASt) entstanden.
        </Typography>
        <Typography gutterBottom>
          Im Projekt wurde eine IVS-Referenzarchitektur für den ruhenden Verkehr unter Berücksichtigung verschiedener
          Rahmenbedingungen und Anforderungen entwickelt. Darüber hinaus wurde ein Datenmodell zur harmonisierten
          Weiterleitung von Parkdaten an den MDM weiterentwickelt auf Basis des aktuell gültigen DATEX II Standard
          CEN/TS 16157-6 &quot;Parking Publications&quot; von 2016. Neben der konzeptionellen Entwicklung wurde die
          Referenzarchitektur und das entwickelte Datenprofil in zwei Testräumen (München und Stuttgart) pilothaft
          umgesetzt.
        </Typography>
        <Typography gutterBottom>
          In München wird in einem Stadtquartier ein integratives Parkraummanagement gezeigt.
        </Typography>
        <Typography gutterBottom>
          In Stuttgart werden hingegen die unterschiedlichen Dienste und Anforderungen rundum das „vernetzte Parken“
          sowie die Integration öffentlicher Verkehrsleitstrategien in den Vordergrund gestellt.
        </Typography>
      </Grid>
    </Grid>
  </ContentPageWrapper>
);

export default AboutPage;
