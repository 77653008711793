import React from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { GeoJsonProperties } from 'geojson';
import { ThemeProvider } from '@mui/material';
import theme from '../setup/theme';
import StreetLinesPopup from '../components/StreetLinesPopup';

type AddStreetLinesProps = {
  map: mapboxgl.Map;
};

type CreateStreetLinesPopupProps = {
  map: mapboxgl.Map;
  coordinates: mapboxgl.LngLat;
  properties: GeoJsonProperties;
};

export const createStreetLinesPopup = ({ properties, coordinates, map }: CreateStreetLinesPopupProps) => {
  const popup = new mapboxgl.Popup({
    closeOnClick: false,
    closeButton: false,
    anchor: 'bottom',
  });

  const closeAction = () => {
    popup.remove();
  };

  map.on('closeAllPopups', () => {
    closeAction();
  });

  const popupContent = document.createElement('div');
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <StreetLinesPopup properties={properties} closeAction={closeAction} />
    </ThemeProvider>,
    popupContent,
  );

  if (map) {
    popup.setLngLat(coordinates).setDOMContent(popupContent).setMaxWidth('100vw').addTo(map);
  }
};

/* eslint-disable no-param-reassign */
export const addStreetLines = ({ map }: AddStreetLinesProps) => {
  map.addSource('street-lines-source', {
    type: 'geojson',
    data: process.env.REACT_APP_MUNICH_PARKING_GEOJSON_URL,
    /* cluster: true,
     * clusterMaxZoom: 18,
     * clusterRadius: 80, */
  });

  map.addLayer(
    {
      id: 'street-lines-layer',
      type: 'line',
      source: 'street-lines-source',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-width': 5,
        'line-color': [
          'step',
          ['get', 'probability'],
          '#B3B3B3', // gray
          0,
          '#F7614C', // red
          25,
          '#FFBA00', // orange
          50,
          '#F2E32F', // yellow
          75,
          '#B0C830', // green
        ],
      },
    },
    'els-layer-marker',
  );

  map.addLayer(
    {
      id: 'street-lines-layer-clickable',
      type: 'line',
      source: 'street-lines-source',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-width': 15,
        'line-opacity': 0,
      },
    },
    'els-layer-marker',
  );

  // handle click-on-feature event -> open big popup
  map.on('click', 'street-lines-layer-clickable', (event) => {
    if (event.originalEvent.cancelBubble) {
      return;
    }
    if (event.features && map) {
      map.flyTo({
        center: event.lngLat,
        offset: [0, 100],
        zoom: 18,
      });

      createStreetLinesPopup({
        coordinates: event.lngLat,
        map,
        properties: event.features[0].properties,
      });
    }
  });

  map.on('mouseenter', 'street-lines-layer-clickable', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'street-lines-layer-clickable', () => {
    map.getCanvas().style.cursor = '';
  });

  // re-fetch data every 5 minutes
  setInterval(() => {
    (map.getSource('street-lines-source') as GeoJSONSource).setData(
      process.env.REACT_APP_MUNICH_PARKING_GEOJSON_URL as string,
    );
  }, 300000);
};
