import * as React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FeatureCollection, GeoJsonProperties, Feature } from 'geojson';
import { useEffect, useState } from 'react';

type StreetLinesPopupProps = {
  closeAction: () => void;
  properties: GeoJsonProperties;
};

const calculateBackgroundColor = (probability: number): string => {
  if (probability <= -1) {
    return '#B3B3B3';
  }
  if (probability <= 25) {
    return '#F7614C';
  }
  if (probability <= 50) {
    return '#FFBA00';
  }
  if (probability <= 75) {
    return '#F2E32F';
  }
  if (probability <= 100) {
    return '#B0C830';
  }
  return 'primary.main';
};

const generateTimeString = (feature: Feature): string => {
  const validFrom = new Date(feature?.properties?.valid_from).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  });
  const validTo = new Date(feature?.properties?.valid_to).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  });

  return `${validFrom}-${validTo}`;
};

const StreetLinesPopup = ({ closeAction, properties }: StreetLinesPopupProps) => {
  const [parkingRuleFeatures, setParkingRuleFeatures] = useState<FeatureCollection>();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_MUNICH_PARKING_RULE_VALIDITY_URL}&cql_filter=parking_rule_id=${properties?.parking_rule_id}`,
    )
      .then((data) => data.json())
      .then((geojson) => setParkingRuleFeatures(geojson));
    // eslint-disable-next-line
  }, [properties?.parking_rule_id]);

  return (
    <Box
      sx={{
        backgroundColor: calculateBackgroundColor(properties?.probability),
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 92%, 56% 92%, 50% 100%, 44% 92%, 0% 92%)',
        fontFamily: 'Source Sans Pro',
        padding: '10px 20px 30px',
        height: {
          xs: '300px',
        },
        width: {
          xs: '90vw',
          sm: '40vw',
          md: '33vw',
          lg: '20vw',
        },
      }}
    >
      <Grid container flexDirection="column">
        <Grid item container>
          <Grid container item xs={10} alignItems="center" justifyContent="flex-start">
            <Typography align="center" lineHeight={1} variant="h6">
              {properties?.street}
            </Typography>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end">
            <CloseIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => closeAction()} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          flexDirection="column"
          spacing={1}
          sx={{
            height: '270px',
            marginTop: '10px',
          }}
        >
          <Grid item>
            {properties?.probability < 0 ? (
              <></>
            ) : (
              <Typography align="left" lineHeight={1} variant="body1">
                <b>{properties?.probability}% Wahrscheinlichkeit</b> einen freien Parkplatz zu finden.
              </Typography>
            )}
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider textAlign="left" sx={{ width: '100%' }}>
              PARKREGELN
            </Divider>
          </Grid>
          <Grid item>
            <Typography align="left" lineHeight={1} variant="body1">
              {properties?.parking_rule_description}
            </Typography>
          </Grid>

          {parkingRuleFeatures?.features.map((feature: Feature) => (
            <Grid key={feature?.id} item container>
              <Grid item xs={12} justifySelf="flex-start">
                <Typography variant="body1">
                  {generateTimeString(feature)} {feature?.properties?.parking_rate_description}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreetLinesPopup;
