import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import useStore from '../setup/global-state';
import CustomDialog from './CustomDialog';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
    },
  },
  contentLarge: {
    [theme.breakpoints.up('xl')]: {
      paddingInline: `${theme.spacing(20)}px ${theme.spacing(20)}px ${theme.spacing(10)}px`,
    },
  },
  contentCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '30%',
    maxWidth: theme.spacing(60),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  subTitle: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'bold',
    fontSize: '24pt',
    paddingTop: 60,
  },
  actions: {
    display: 'block',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(45),
    paddingLeft: theme.spacing(2),
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      left: 0,
      top: theme.spacing(-2),
      height: theme.spacing(2),
    },

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(8),
    },

    '& .MuiButton-root': {
      display: 'block',
      margin: '0 auto',
    },
  },
  actionsLarge: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  button: {
    color: 'black',
    backgroundColor: '#FFBA00',
    boxShadow: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 5,
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    fontSize: 18,
    borderRadius: 0,
  },
  divider: {
    width: 'auto',
    height: '10px',
    display: 'inline-block',
  },
}));

const CarTypeModal = () => {
  const classes = useStyles();

  const setMotorType = useStore((state) => state.setMotorType);

  return (
    <CustomDialog position="center">
      <DialogContent className={clsx(classes.content, classes.contentLarge)}>
        <Box className={classes.contentCenter}>
          <Typography variant="h2" className={classes.subTitle} align="center">
            Was für ein Auto fahren Sie?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions className={clsx(classes.actions, classes.contentLarge)}>
        <Button onClick={() => setMotorType('EHMOTOR')} className={classes.button}>
          Elektro- oder Hybridmotor
        </Button>

        <div className={classes.divider} />

        <Button onClick={() => setMotorType('PMOTOR')} className={classes.button}>
          Verbrennungsmotor
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default CarTypeModal;
