import React from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { GeoJsonProperties } from 'geojson';
import { ThemeProvider } from '@mui/material';
import markerSVG from '../assets/marker.svg';
import circleSVG from '../assets/circle.svg';
import eStationSVG from '../assets/estation.svg';
import theme from '../setup/theme';
import ElsMarkerPopup from '../components/ElsMarkerPopup';

type AddElsMarkersProps = {
  map: mapboxgl.Map;
};

type CreateElsMarkerPopupProps = {
  map: mapboxgl.Map;
  coordinates: [number, number];
  properties: GeoJsonProperties;
};

export const createElsMarkerPopup = ({ properties, coordinates, map }: CreateElsMarkerPopupProps) => {
  const popup = new mapboxgl.Popup({
    className: 'orange-popup',
    closeOnClick: false,
    closeButton: false,
    anchor: 'bottom',
  });

  const closeAction = () => {
    popup.remove();

    map.setLayoutProperty('els-layer-marker', 'visibility', 'visible');
    map.setLayoutProperty('els-layer-icon', 'visibility', 'visible');
    map.setLayoutProperty('els-layer-clusters', 'visibility', 'visible');
  };

  map.on('closeAllPopups', () => {
    closeAction();
  });

  const popupContent = document.createElement('div');
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <ElsMarkerPopup properties={properties} closeAction={closeAction} />
    </ThemeProvider>,
    popupContent,
  );

  if (map) {
    popup.setLngLat(coordinates).setDOMContent(popupContent).setMaxWidth('100vw').addTo(map);
  }
};

/* eslint-disable no-param-reassign */
export const addElsMarkers = ({ map }: AddElsMarkersProps) => {
  const markerImg = new Image(100, 120);
  markerImg.onload = () => map?.addImage('markerSVG', markerImg);
  markerImg.src = markerSVG;

  const circleImg = new Image(100, 100);
  circleImg.onload = () => map?.addImage('circleSVG', circleImg);
  circleImg.src = circleSVG;

  const elsIconImg = new Image(35, 35);
  elsIconImg.onload = () => map?.addImage('elsIconSVG', elsIconImg);
  elsIconImg.src = eStationSVG;

  map.addSource('els-source', {
    type: 'geojson',
    data: process.env.REACT_APP_MUNICH_ELS_GEOJSON_URL,
    cluster: true,
    clusterMaxZoom: 16,
    clusterRadius: 80,
  });

  map.addLayer({
    id: 'els-layer-marker',
    type: 'symbol',
    source: 'els-source',
    layout: {
      'text-field': ['get', 'address'],
      'text-size': 13,
      'icon-image': 'markerSVG',
      'icon-anchor': 'bottom',
      'icon-text-fit': 'width',
      'icon-text-fit-padding': [15, 13, 15, 13],
      'text-allow-overlap': true,
      'icon-allow-overlap': true,
      'text-justify': 'center',
      'text-max-width': 9,
      'text-offset': [0, -5],
      'text-padding': 2,
    },
    filter: ['!has', 'point_count'],
  });

  map.addLayer({
    id: 'els-layer-icon',
    type: 'symbol',
    source: 'els-source',
    layout: {
      'icon-image': 'elsIconSVG',
      'text-allow-overlap': true,
      'icon-allow-overlap': true,
      'icon-offset': [0, -90],
    },
    filter: ['!has', 'point_count'],
  });

  map.addLayer({
    id: 'els-layer-clusters',
    type: 'symbol',
    source: 'els-source',
    layout: {
      'text-field': ['get', 'point_count'],
      'text-size': 25,
      'icon-image': 'circleSVG',
      'icon-size': 0.6,
      'text-allow-overlap': false,
      'icon-allow-overlap': true,
      'text-justify': 'center',
    },
    filter: ['has', 'point_count'],
  });

  map.on('click', 'els-layer-marker', (event) => {
    event.originalEvent.cancelBubble = true;
    if (event.features && event.features[0].geometry.type === 'Point' && map) {
      // get coordinates of clicked feature
      const coords = event.features[0].geometry.coordinates.slice() as [number, number];

      map.flyTo({
        center: coords,
        offset: [0, 100],
        zoom: 18,
      });

      while (Math.abs(event.lngLat.lng - coords[0]) > 180) {
        coords[0] += event.lngLat.lng > coords[0] ? 360 : -360;
      }

      map.setLayoutProperty('els-layer-marker', 'visibility', 'none');
      map.setLayoutProperty('els-layer-icon', 'visibility', 'none');
      map.setLayoutProperty('els-layer-clusters', 'visibility', 'none');

      createElsMarkerPopup({
        coordinates: coords,
        map,
        properties: event.features[0].properties,
      });
    }
  });

  map.on('mouseenter', 'els-layer-marker', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'els-layer-marker', () => {
    map.getCanvas().style.cursor = '';
  });

  // re-fetch data every 5 minutes
  setInterval(() => {
    (map.getSource('els-source') as GeoJSONSource).setData(process.env.REACT_APP_MUNICH_ELS_GEOJSON_URL as string);
  }, 300000);
};
